.productModalContainer{
    width: 42%;
    height: fit-content;
    margin: auto;
    @media screen and (max-width:480px) {
        width: 100%;
        
    }
    @media screen and (max-width:768px) {
        width: 80%;
        
    }
    .productModal{
        background-color: #ffffff;
        padding: 8%;
        border-radius: 0.5rem;
        .field{
            >div{
                width: 100%;
            }
            textarea{
                resize: unset;
                width: 100%;
                height: 20rem;
            }
        }
        .action{
            display: flex;
            justify-content: right;
            margin-block-start: 1rem;
            >div:nth-child(1){
                margin-inline-end: 0.5rem;
            }
        }
    }

}