.ourProductsContainer {
    padding-block: 5%;
    padding-inline: 8%;

    >div:nth-child(1) {
        display: flex;
        justify-content: center;

        div {
            width: 55%;
            text-align: center;
        }
    }

    .ourProducts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-block-start: 2.5rem;

        >div {
            width: 24%;
            margin-block-end: 1rem;

        }
    }

}

@media screen and (max-width:480px) {
    .ourProductsContainer {
        padding-block-end: 10%;
        padding-inline: 5%;

        >div:nth-child(1) {

            div {
                width: 100%;
            }
        }

        .ourProducts {

            >div {
                width: 100%;

            }
        }

    }

}

@media screen and (max-width:768px) {
    .ourProductsContainer {
        padding-block-end: 10%;
        padding-inline: 5%;

        >div:nth-child(1) {

            div {
                width: 100%;
            }
        }

        .ourProducts {

            >div {
                width: 100%;

            }
        }

    }

}
@media (min-device-width:769px) and (max-device-width:1024px){
    .ourProductsContainer {
        padding-block: 5%;
        padding-inline: 4%;
    
        >div:nth-child(1) {
            display: flex;
            justify-content: center;
    
            div {
                width: 55%;
                text-align: center;
            }
        }
    
        .ourProducts {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-block-start: 2.5rem;
    
            >div {
                width: 24%;
                margin-block-end: 1rem;
    
            }
        }
    
    }
}