.contactFormContainer {
    padding: 3.5rem;
    border-radius: 2rem 0 2rem 0;
    background-image: linear-gradient(to bottom left, #45B29C 0%, #1D2B35 100%);
    color: #ffffff;

    >div {
        width: 100%;
        margin-block-end: 0.6rem;
        background-color: #ffffff;
    }

    >div:nth-last-child(2) {
        margin-block-start: 0.5rem;
        background-color: unset;

        button {
            width: 100%;
        }
    }

}

@media screen and (max-width:480px) {
    // .contactFormContainer {
    //     padding: 2rem;
    // }
}