.newsCard {
    >div:nth-child(1) {
        img {
            width: 100%;
            height: inherit;
            object-fit: cover;
            border-radius: 2rem 0 2rem 0;
        }
    }

    >div:nth-child(2) {
        padding-inline-start: 2.5%;
        position: relative;

        >div:nth-last-child(1) {
            position: absolute;
            bottom: 10%;
            right: 0%;
        }
    }
}