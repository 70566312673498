.commonBanner{
    padding-inline: 8%;
    padding-block: 8.5%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom right, #ffffff 0%, #45B29C 100%);
    @media screen and (max-width:480px) {
        padding-block: 4rem;
        
    }

}

@media (min-device-width:769px) and (max-device-width:1024px){
    .commonBanner{
        padding-inline: 4%;
    }
    
}