.whoWeAreContainer {
    background-color: #1D2C36;

    >div:nth-child(1) {
        padding-inline-start: 8%;
        margin-block: auto;
        padding-inline-end: 5%;
        color: #ffffff;

        h5 {
            color: #ffffff;
        }

        >div:nth-child(2) {
            margin-block-start: 4rem;

        }
    }

    >div:nth-child(2) {
        img {
            width: 100%;
            height: 35rem;
            object-fit: cover;
        }
    }
}

@media screen and (max-width:480px) {
    .whoWeAreContainer {

        >div:nth-child(1) {
            padding-block: 10%;
            padding-inline: 5%;

            h4 {
                font-size: 2rem;
            }
        }

        >div:nth-child(2) {
            img {
                width: 100%;
                height: 21rem;
                object-fit: cover;
            }
        }
    }

}
@media (min-device-width:769px) and (max-device-width:1024px){
    .whoWeAreContainer{
        >div:nth-child(1) {
            padding-inline-start: 4%;
        }
    }
    
}