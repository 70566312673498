.headingTextContainer{
    >div{
        h5{
            margin-block: auto;
        }
    }
}

@media screen and (max-width:768px){
    .headingTextContainer{
        h4{
            font-size:1.8rem;
            text-wrap: nowrap;
        }
        >div{
            
            h5{
                margin-block: auto;
            }
        }
    }   
}