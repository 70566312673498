.careerContainer {
  position: relative;
  margin-block-end: 48rem;

  >div:nth-child(1) {
    justify-content: left;
  }

  .career {
    width: 50%;
    margin: auto;
    background-color: #ffffff;
    padding: 5%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -18%);
    border-right: 20px solid #45B29C;
    border-bottom: 20px solid #45B29C;

    .field {


      >div {
        margin-block: 0.5rem;
      }

    }
  }

  .action {
    display: flex;
    justify-content: right;
    margin-block-start: 1rem;

    >div {
      button {
        width: 14rem;
      }
    }
  }

}

@media screen and (max-width:480px) {
  .careerContainer {
    position: relative;
    margin-block-end: 48rem;
  
    >div:nth-child(1) {
      justify-content: left;
    }
  
    .career {
      width: 90%;
      margin: auto;
      background-color: #ffffff;
      padding: 5%;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 5%);
    }
  }
}