.molecularBiologyContainer {
    .molecularBiology {
        padding-inline: 8%;
        margin-block: 5%;

        .ourProducts {
            padding-inline: 0%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .products {
                width: 24%;
                margin-block: 0.5rem;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .molecularBiologyContainer {
        .molecularBiology {
            padding-inline: 5%;
            margin-block: 5%;

            .ourProducts {
                padding-inline: 0%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .products {
                    width: 100%;
                    margin-block: 0.5rem;
                }
            }
        }
    }

}
@media (min-device-width:769px) and (max-device-width:1024px){
    .molecularBiologyContainer {
        .molecularBiology {
            padding-inline: 4%;
            margin-block: 5%;
    
            .ourProducts {
                padding-inline: 0%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
    
                .products {
                    width: 24%;
                    margin-block: 0.5rem;
                    >div{
                        >div{
                            padding-block-start: 1%;
                            h4{
                                font-size: 0.8rem;
                            }
                            p{
                                font-size: 0.6rem;
                                line-height: 1.15rem;
                            }
                        }
                    }
                }
            }
        }
    }
}