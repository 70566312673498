.footerContainer {
    padding-block: 5%;
    padding-inline: 8%;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width:480px) {
    .footerContainer {
        padding-block: 10%;
        padding-inline: 5%;
        display: flex;
        justify-content: space-between;
        h5{
            font-size: 1.6rem;
            font-weight: 600;
        }

        >div:nth-child(2) {
            margin-block: 1.8rem;
        }
    }

}
@media (min-device-width:769px) and (max-device-width:1024px){
     .footerContainer{
        padding-inline: 4%;
     }
}