.productCategoriesContainer {
    padding-inline: 8%;
    padding-block: 5%;

    .productCategories {
        display: flex;
        justify-content: space-between;
        padding-block-start: 5%;

        .categories {
            width: 24%;
            border-radius: 2rem 0 2re 0;
            background-image: linear-gradient(to bottom right, #ffffff 0%, #45B39D 100%);

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 2rem 0 2rem 0;
            }

            >div {
                padding: 5%;

                p {
                    font-size: 0.8rem;
                    line-height: 1.5rem;
                    margin-block: 0.8rem;
                }

                >div {

                    button {
                        width: 100%;

                    }
                }

            }

            >div:nth-child(2) {
                >div {
                    display: flex;
                    justify-content: space-between;

                    >div {
                        width: 49.5%;

                        button {
                            padding-inline: 0%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:480px) {
    .productCategoriesContainer {
        padding-inline: 5%;
        padding-block: 10%;

        >div:nth-child(1) {
            h4 {
                font-size: 2rem;
            }
        }

        .productCategories {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-block-start: 5%;

            .categories {
                width: 100%;
                margin-block: 0.5rem;
                border-radius: 2rem 0 2re 0;

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 2rem 0 2rem 0;
                }

                >div {
                    padding: 5%;

                    p {
                        font-size: 0.8rem;
                        line-height: 1.5rem;
                        margin-block: 0.8rem;
                    }

                    >div {

                        button {
                            width: 100%;

                        }
                    }

                }

                >div:nth-child(2) {
                    >div {
                        display: flex;
                        justify-content: space-between;

                        >div {
                            width: 49.5%;

                            button {
                                padding-inline: 0%;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media screen and (max-width:768px) {
    .productCategoriesContainer {
        padding-inline: 5%;
        padding-block: 10%;

        >div:nth-child(1) {
            h4 {
                font-size: 2rem;
            }
        }

        .productCategories {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-block-start: 5%;

            .categories {
                width: 100%;
                margin-block: 0.5rem;
                border-radius: 2rem 0 2re 0;

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 2rem 0 2rem 0;
                }

                >div {
                    padding: 5%;

                    p {
                        font-size: 0.8rem;
                        line-height: 1.5rem;
                        margin-block: 0.8rem;
                    }

                    >div {

                        button {
                            width: 100%;

                        }
                    }

                }

                >div:nth-child(2) {
                    >div {
                        display: flex;
                        justify-content: space-between;

                        >div {
                            width: 49.5%;

                            button {
                                padding-inline: 0%;
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (min-device-width:769px) and (max-device-width:1024px) {
    .productCategoriesContainer {
        padding-inline: 4%;
        padding-block: 5%;

        .productCategories {
            display: flex;
            justify-content: space-between;
            padding-block-start: 5%;

            .categories {
                width: 24%;
                border-radius: 2rem 0 2re 0;
                background-image: linear-gradient(to bottom right, #ffffff 0%, #45B39D 100%);

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 2rem 0 2rem 0;
                }

                >div {
                    padding: 5%;

                    p {
                        font-size: 0.8rem;
                        line-height: 1.25rem;
                        margin-block: 0.8rem;
                    }

                    >div {
                        width: 100%;

                        button {
                            font-size: 0.6rem;
                        }
                    }

                }

                >div:nth-child(2) {
                    >div {
                        display: flex;
                        justify-content: space-between;

                        >div {
                            width: 49.5%;

                            button {
                                padding-inline: 0%;
                            }
                        }
                    }
                }
            }
            >div:nth-child(2),
            >div:nth-child(4){
                >div{
                    >div{
                        margin-block-start: 2rem;
                    }
                }
            }
        }
    }
}