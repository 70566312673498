.visionAndMissionsContainer {
    padding-block-start: 5%;
    padding-inline: 10%;

    .visionAndMissions {
        display: flex;
        justify-content: space-between;
        margin-block: 2rem;
        >div:nth-child(1),
        >div:nth-child(2) {
            width: 60%;
            margin-block: auto;

            img {
                width: 100%;
                height: fit-content;
                object-fit: cover;
                border-radius: 2rem 0 2rem 0;
            }
            p{
                line-height: 1.8rem;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .visionAndMissionsContainer{
        padding-block: 10%;
        padding-inline: 5%;
        .visionAndMissions {
            display: flex;
            justify-content: space-between;
            margin-block: 2rem;
            >div:nth-child(1),
            >div:nth-child(2) {
                width: 100%;
            }
            >div:nth-child(2){
                margin-block-start: 2rem;
            }
        }
        
    }
}
@media (min-device-width:769px) and (max-device-width:1024px){
    .visionAndMissionsContainer{
        padding-inline: 4%;
    }
    
}