.bannerContainer {
    padding-inline: 8%;
    padding-block: 7.5%;
    display: flex;
    justify-content: space-between;

    >div:nth-child(1) {
        width: 45%;
        position: relative;

        >div:nth-child(2) {
            position: absolute;
            bottom: 30%;
        }
    }

    >div:nth-child(2) {
        width: 45%;
        display: flex;
        justify-content: right;


        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 4rem 0 4rem 0;
        }
    }
}

@media screen and (max-width:480px) {
    .bannerContainer {
        display: flex !important;
        flex-direction: column !important;
        padding-inline: 5%;

        >div {
            width: 100% !important;

            img {
                width: 100% !important;
            }
        }

        >div:nth-child(1) {
            height: 20rem;
            margin-block-end: 2rem;

            >div:nth-child(2) {
                position: absolute;
                bottom: 0%;
            }
        }
    }

}

@media screen and (max-width:768px) {
    .bannerContainer {
        display: flex !important;
        flex-direction: column !important;
        padding-inline: 5%;

        >div {
            width: 100% !important;

            img {
                width: 100% !important;
            }
        }

        >div:nth-child(1) {
            height: fit-content;
            margin-block-end: 2rem;


            >div:nth-child(2) {
                position: absolute;
                top: 100%;
            }
        }

        >div:nth-child(2) {
            margin-block-start: 5%;

        }
    }
}

@media (min-device-width:769px) and (max-device-width:1024px) {
    .bannerContainer {
        padding-inline: 4%;
        padding-block: 7.5%;
        display: flex;
        justify-content: space-between;

        >div:nth-child(1) {
            width: 45%;
            position: relative;

            >div:nth-child(2) {
                position: absolute;
                top: 100%;
            }
        }

        >div:nth-child(2) {
            width: 45%;
            display: flex;
            justify-content: right;


            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 4rem 0 4rem 0;
            }
        }
    }
}