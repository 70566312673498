.companyOverview {
    padding-block: 5%;
    padding-inline: 8%;

    >div:nth-child(1) {
        padding-inline-end: 5%;
        position: relative;

        >div:nth-child(2) {
            position: absolute;
            bottom: 10%;
        }

        ul {
            margin-block: 1.5rem;

            li {
                margin-block: 0.8rem;
                line-height: 1.5rem;
            }
        }
    }

    >div:nth-child(2) {
        img {
            width: 100%;
            height: inherit;
            object-fit: cover;
            border-radius: 2rem 0 2rem 0;
        }
    }
}

@media screen and (max-width:480px) {
    .companyOverview {
        padding-block: 10%;
        padding-inline: 5%;

        >div:nth-child(1) {
            padding-inline-end: 5%;
            position: relative;
            h4{
                font-size: 2rem;
            }
        }

        >div:nth-child(2) {
            margin-block-start: 2rem;
            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
                border-radius: 2rem 0 2rem 0;
            }
        }
    }

}