.newsSectionContainer {
    padding-block: 5%;
    padding-inline: 8%;
    border-bottom: 1px solid #e3e3e3;
    >div:nth-child(1){
        >div{
            width: 55%;
        }
    }

    .newsSection {
        margin-block-start: 5%;

        // >div:nth-child(2),
        // >div:nth-child(4) {
        //     >div {
        //         display: flex;
        //         flex-direction: row-reverse;

        //         >div:nth-child(2) {
        //             padding-inline-start: 0%;
        //             padding-inline-end: 5%;
        //             >div:nth-last-child(1){
        //                 left: 0%;
        //             }

        //         }
        //     }
        // }

        >div:nth-last-child(1) {
            hr {
                display: none;
            }

        }
    }
}