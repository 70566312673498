.queryNowContainer {
    width: 40%;
    height: fit-content;
    margin: auto;
    @media screen and (max-width:480px) {
        width: 100%;
        
    }
    @media screen and (max-width:768px) {
        width: 80%;
        
    }
    // @media (min-device-width:769px) and (max-device-width:1024px){
    //     width: 60%;
    // }

    .queryNow {
        background-image: linear-gradient(to bottom left, #45B29C 0%, #1D2B35 100%);
        padding: 10%;
        border-radius: 2rem 0 2rem 0;
        color: #ffffff;

        .fields {
            color: #ffffff;
            >div{
                >div{
                    background-color: #ffffff;
                }
            }
  
        }

        .action {
            display: flex;
            justify-content: right;
            margin-block-start: 1rem;
            >div{
                button{
                    border: 1px solid #ffffff;
                    background-color: unset;
                    padding-inline: 2.75rem;
                    &:hover{
                        background-color: #1D2B35;

                    }
                }
            }

            >div:nth-child(1) {
                margin-inline-end: 0.5rem;
            }
        }
    }
}