.headingContainer {
    background-image: linear-gradient(to right, #1C2833 0%, #45B39D 100%);
    padding-block: 1.5%;
    padding-inline: 8%;
    position: relative;

    >div:nth-child(2) {
        display: flex;
        justify-content: space-between;

        p {
            margin-block: auto;
            color: #ffffff;
            cursor: pointer;
        }

        .mobileMenu {
            position: absolute;
            top: 100%;
            width: 100%;
            left: 0%;
            background-color: #ffffff;
            border-bottom: 1px solid #444444;
            z-index: 1;

            >div {
                ul {
                    background-color: aqua;
                }
            }
        }
    }
}

@media screen and (max-width:480px) {
    .headingContainer {
        display: flex;
        justify-content: space-between;
        padding-inline: 5%;
        padding-block: 6%;

        >div {
            width: fit-content;
            height: fit-content;
            margin-block: auto;
        }
    }

}

@media screen and (max-width:768px) {
    .headingContainer {
        display: flex;
        justify-content: space-between;
        padding-inline: 5%;
        padding-block: 1.5%;

        >div {
            width: fit-content;
            height: fit-content;
            margin-block: auto;
        }
    }

}
@media (min-device-width:769px) and (max-device-width:1024px){
    .headingContainer{
       padding-inline: 4%;
    }
}