.layoutContainer {

    .leftSide {
        background-color: #fbfbfb;
        height: 100vh;
    }

    .rightSide {
        padding-block: 5.1rem;
    }
}