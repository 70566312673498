.contactContainer {
    padding-block: 5%;
    padding-inline: 8%;
    border-bottom: 1px solid #e3e3e3;

    >div:nth-child(1) {
        margin: auto;
        width: 50%;
    }

    >div:nth-child(2) {
        display: flex;
        justify-content: right;
        width: 50%;

        >div {
            width: 80%;
        }
    }

}

@media screen and (max-width:480px) {
    .contactContainer {
        padding-block: 10%;
        padding-inline: 5%;
        border-bottom: 1px solid #e3e3e3;

        >div:nth-child(1) {
            position: relative;
            height: 23rem;

            >div:nth-last-child(1) {
                position: absolute;
                bottom: 0%;
            }
        }

        >div:nth-child(2) {
            display: flex;
            margin-inline: auto ;
            margin-block-start: 2rem;

            >div {
                width: 100%;
            }
        }

    }

}
@media screen and (max-width:768px) {
    .contactContainer {
        padding-block: 10%;
        padding-inline: 5%;
        border-bottom: 1px solid #e3e3e3;
        display: flex !important;
        flex-direction: column !important;

        >div:nth-child(1) {
            position: relative;
            height: 23rem;
            margin: unset;
            width: 100% ;


            >div:nth-last-child(1) {
                position: absolute;
                bottom: 0%;
            }
        }

        >div:nth-child(2) {
            display: flex;
            margin-inline: auto ;
            margin-block-start: 2rem;
            width: 100% ;

            >div {
                width: 100%;
            }
        }

    }

}
@media (min-device-width:769px) and (max-device-width:1024px){
    .contactContainer {
        padding-block: 5%;
        padding-inline: 4%;
        border-bottom: 1px solid #e3e3e3;
    
        >div:nth-child(1) {
            margin: auto;
            width: 50%;
        }
    
        >div:nth-child(2) {
            display: flex;
            justify-content: right;
            width: 50%;
    
            >div {
                width: 100%;
            }
        }
    
    }
}