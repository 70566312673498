.loginContainer {
    height: 100vh;
    position: relative;

    .login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35%;
        height: fit-content;
        margin: auto;
        padding: 5%;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        >div:nth-child(2) {
            >div {
                width: 100%;
                margin-block: 0.5rem;
            }
        }
        >div:nth-child(3){
            margin-block-start: 1rem;
            button{
                width: 100%;
                padding-block: 0.6rem;
            }

        }
    }
}