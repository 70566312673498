.productContainer {
    border-radius: 1rem 0 1rem 0;
    background-image: linear-gradient(to bottom right, #ffffff 0%, #45B39D 100%);
    position: relative;

    // border: 1px solid #45B19C;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    img {
        width: 100%;
        height: inherit;
        border-radius: 1rem 0 1rem 0;

    }

    >div {
        padding: 1rem;
        // color: #ffffff;
        height: 10rem;

        p {
            font-size: 0.85rem;
            margin-block: 0.5rem;
            line-height: 1.5rem;
        }

        >div {
            position: absolute;
            bottom: 4%;
            width: 90%;
            button {
                width: 100%;
            }
        }
    }
}

@media (min-device-width:769px) and (max-device-width:1024px){
    .productContainer {
        border-radius: 1rem 0 1rem 0;
        background-image: linear-gradient(to bottom right, #ffffff 0%, #45B39D 100%);
        position: relative;    
        // border: 1px solid #45B19C;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
        img {
            width: 100%;
            height: inherit;
            border-radius: 1rem 0 1rem 0;
    
        }
    
        >div {
            padding: 1rem;
            // color: #ffffff;
            height: 10rem;
    
            p {
                font-size: 0.75rem;
                margin-block: 0.5rem;
                line-height: 1.25rem;
            }
    
            >div {
                position: absolute;
                bottom: 4%;
                width: 80%;
                button {
                    width: 100%;
                    padding-block: 3%;
                    font-size: 0.6rem;
                }
            }
        }
    }
}