.productsContainer {

    >div:nth-child(1) {
        display: flex;
        justify-content: right;
        padding-inline: 2%;

        >div {
            width: fit-content;
            margin-block-end: 0.5rem;
        }
    }
}